<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form method="post" action="javascript:void(0)"
              ref="form_add_or_update"
              class="repeater-form" >
        <b-row>
          <b-col md="4">
            <b-card-text>
              <span>Select Class </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="class"
                rules="required"
            >
              <select name="classes_id" @change="filterSections" v-model="classes_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option  :value="null">Select One</option>
                <option v-for="(cls, key) in classes" :value="cls.id" :key="key">{{cls.name}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!-- <b-col md="4">
            <b-card-text>
              <span>Select Versions </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="version"
                rules="required"
            >
              <select name="version_id" v-model="version_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option  :value="null">Select One</option>
                <option v-for="(version, key) in versions" :value="version.id" :key="key">{{version.name}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>Select Shifts </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="shift"
                rules="required"
            >
              <select name="shift_id" v-model="shift_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option  :value="null">Select One</option>
                <option v-for="(shift, key) in shifts" :value="shift.id" :key="key">{{shift.name}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col> -->
          <b-col md="4">
            <b-card-text>
              <span>Select Institution </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="wing"
                rules="required"
            >
              <select name="wing_id" @change="filterSections" v-model="wing_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option  :value="null">Select One</option>
                <option v-for="(wing, key) in wings" :value="wing.id" :key="key">{{wing.name}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>Select Student Groups </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="student group"
                rules="required"
            >
              <select name="student_group_id" v-model="student_group_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option  :value="null">Select One</option>
                <option v-for="(sgrp, key) in student_groups" :value="sgrp.id" :key="key">{{sgrp.name}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>Select Academic Years </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="academic year"
                rules="required"
            >
              <select name="academic_year_id" @change="filterSections" v-model="academic_year_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option  :value="null">Select One</option>
                <option v-for="(ayear, key) in academic_years" :value="ayear.id" :key="key">{{ayear.year}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>Select Section </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="section"
                rules="required"
            >
              <select name="section_id" v-model="section_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option  :value="null">Select One</option>
                <option v-for="(section, key) in filter_sections" :value="section.id" :key="key">{{section.name}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-card-text>
              <span>Select Subject </span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="subject_id"
                rules="required"
            >
              <select name="subject_id" v-model="subject_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                <option  :value="null">Select One</option>
                <option v-for="(subject, key) in subjects" :key="key" :value="subject.id">{{subject.name+' '+ subject.code}}</option>
              </select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Select Month') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('date')"
                  rules="required"
              >
                <b-form-input v-model="attendance_date" required type="month"
                                   name="attendance_date" placeholder="select month"
                                   :state="errors.length > 0 ? false:null"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <template>
          <b-table-simple v-if="attendance_date" hover small caption-top responsive>
            <b-thead>
              <b-th class="text-center"  :colspan="1">Student No</b-th>
              <b-th class="text-center"  :colspan="1">Name</b-th>
              <b-th class="text-center" v-for="(day,key) in days" :key="key">{{ day+1 }}</b-th>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(at,k) in stds" :key="k">
                <b-th class="text-left">{{ cadetIdByID(at.id) }}</b-th>
                <b-th class="text-left">{{ studentNameByID(at.id) }}</b-th>
                <b-th class="text-center" v-for="(day,key) in days" :key="key">{{ atStatus(at,day) }}</b-th>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </template>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BTableSimple,BThead,BTr,BTh,BTbody,
  BFormGroup,BFormInput,
  BForm,BRow, BCol,BCardText,
} from 'bootstrap-vue'
import {required} from '@validations'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import moment from "moment"
import apiCall from "@/libs/axios";
export default {
  name:'ViewAttendance',
  components: {
    BCardCode,BFormGroup,BFormInput,
    BForm,BRow, BCol,BCardText,ValidationProvider, ValidationObserver,
    BTableSimple,BThead,BTr,BTh,BTbody,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      required,
      attendance_date:null,
      days:[],
      classes:[],
      versions:[],
      student_groups:[],
      shifts:[],
      wings:[],
      all_sections:[],
      filter_sections:[],
      classes_id:null,
      version_id:null,
      shift_id:null,
      wing_id:null,
      student_group_id:null,
      section_id:null,
      academic_year_id:null,
      subject_id:null,
    }
  },
  methods:{
    /*method for get all days in selected month*/
    getDays(){
      this.days=Array.from(Array(moment(this.attendance_date).daysInMonth()).keys());
    },
    /*
    * method for get attendance status
    * */
    atStatus(std,day){
      let ff = this.attendances.find(item=>item.student_id===std.id && item.attendance_date === moment(this.attendance_date+'-'+day).format('Y-MM-DD'));
      if(ff){
        return this.attendanceStatusByID(ff.attendance_status_id);
      }
      else return null;
    },
    /*
    * method for get all active class wise version, shift, student groups
    * */
    async getAll(){
      await apiCall.get('/get/active/class/with/v/s/sg/sections').then((response)=>{
        this.classes = response.data;
      }).catch(()=>{
        this.classes=[];
      });
    },

    /*
    * method for filter sections
    * */
    filterSections(){
      this.section_id=null;
      if (!this.wing_id || !this.academic_year_id) this.filter_sections=[];
      else{
        this.filter_sections = this.all_sections.map((item)=>{
          if (item.pivot.wing_id == this.wing_id && item.pivot.academic_year_id == this.academic_year_id) return item;
        }).filter(Boolean);
      }
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    /*get this employee students*/
    stds(){
      return this.$store.getters.students.filter(item=>{
        if(item.classes_id=== parseInt(this.classes_id) && item.version_id===parseInt(this.version_id) &&
            item.shift_id===parseInt(this.shift_id) && item.wing_id===parseInt(this.wing_id) &&
            item.student_group_id===parseInt(this.student_group_id) && item.section_id===parseInt(this.section_id) &&
            item.academic_year_id===parseInt(this.academic_year_id)
        ) return item;
      });
    },
    attendances(){
      return this.$store.getters.attendance_students.filter(item=>{
        if(item.classes_id=== parseInt(this.classes_id) && item.version_id===parseInt(this.version_id) &&
            item.shift_id===parseInt(this.shift_id) && item.wing_id===parseInt(this.wing_id) &&
            item.student_group_id===parseInt(this.student_group_id) && item.section_id===parseInt(this.section_id) &&
            item.academic_year_id===parseInt(this.academic_year_id)
        ) return item;
      });
    },
    subjects(){
      let sub = this.$store.getters.subjects;
      sub = sub.map((item)=>{
        if (item.classes_id == this.classes_id && item.version_id == this.version_id && item.student_group_id == this.student_group_id) return item;
      }).filter(Boolean);
      return sub;
    },
    ...mapGetters(['students','studentIdByID','studentNameByID','attendance_statuses', 'cadetIdByID',
      'attendanceStatusByID','attendance_students','academic_years']),
  },
  created() {
    this.getAll();
    if(this.students.length <1) this.$store.dispatch('GET_ALL_STUDENT');
    if(this.attendance_statuses.length <1) this.$store.dispatch('GET_ALL_ATTENDANCE_STATUS');
    if(this.attendance_students.length <1) this.$store.dispatch('GET_ALL_STUDENT_ATTENDANCE');
    if(this.subjects.length <1) this.$store.dispatch('GET_ALL_SUBJECT');
    if(this.academic_years.length <1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
  },
  watch: {
    attendance_date(){
      this.getDays();
    },
    /*watcher for class on change*/
    classes_id(){
      let s_classes = this.classes.find(item=>item.id===this.classes_id);
      this.version_id=null;
      this.shift_id=null;
      this.wing_id=null;
      this.student_group_id=null;
      this.subject_id=null;
      if (s_classes){
        this.versions = s_classes.versions??[];
        this.shifts = s_classes.shifts??[];
        this.wings = s_classes.wings??[];
        this.student_groups = s_classes.student_groups??[];
        this.all_sections = s_classes.sections??[];
      }else {
        this.versions =[];
        this.shifts =[];
        this.wings =[];
        this.student_groups =[];
        this.all_sections =[];
      }
    },
    version_id(){
      this.subject_id=null;
    },
    student_group_id(){
      this.subject_id=null;
    }
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
